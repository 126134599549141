import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Button } from '@keytrade/components-button';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import parse, { domToReact } from 'html-react-parser';

import Page from '@/components/Page';
import Container from '@/components/Container';
import Paragraph from '@/components/Paragraph';
import Title from '@/components/Title';
import InternalLink from '@/components/InternalLink';
import { LinkStyle } from '@/components/Link';
import { generateSlug } from '@/utils/SlugUtils';
import { mediaQuery } from '@/utils/helpers';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;

const PageWrapper = styled.div`
  margin: 112px 0 72px;
  ${mediaQuery.md('margin: 185px 0 125px;')}
`;

const MetaList = styled.ul`
  margin: 0 0 3rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem 4.8rem;
  list-style: none;
`;

const MetaItemTitle = styled.div`
  font-size: 1.6rem;
  color: ${colors.BlueMoon};
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  border-top: 1px solid ${colors.BlueLight};
  border-bottom: 1px solid ${colors.BlueLight};

  ${mediaQuery.md('padding: 2.8rem 0;')}
`;

const PubDate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.4rem;
  color: ${colors.BlueMoon};
`;

const Description = styled.main`
  margin-bottom: 2.4rem;
  padding: 0.4rem 0 3.2rem;
  border-bottom: 1px solid ${colors.BlueLight};
  word-wrap: break-word;

  ${mediaQuery.md(`
    margin-bottom: 4.8rem;
    padding: 1.2rem 0 7.2rem;
  `)}

  ul, ol {
    position: relative;
    font-size: 1.8rem;
    line-height: 1.5;
    margin: 1.2rem 0;
  }

  li {
    margin-top: 1.2rem;
    counter-increment: listcounter;
    list-style: none;
  }

  ul {
    padding-left: 1.6rem;

    li::before {
      content: '•';
      font-size: 1em;
      position: absolute;
      left: 0;
      color: rgb(44, 59, 69);
      display: block;
      width: 10px;
      height: 10px;
    }
  }
  ol {
    padding-left: 2rem;

    li::before {
      content: counters(listcounter, '.');
      position: absolute;
      left: 0;
    }
  }
`;

const ApplicationFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

type JobsListBlockId = {
  id: string;
};

const JobDetailTemplate: React.FC<JobsListBlockId> = (props) => {
  const { t } = useTranslations('jobs');
  const data = props.data;
  const id = props.id;

  const jobData = data.allVacanciesJson.nodes.find(
    (job) => job.languageId === props.pageContext.locale,
  );

  data.contentfulPage = {};
  data.contentfulPage.node_locale = props.pageContext.locale;
  data.contentfulPage.seoMetadata = {
    seoTitle: jobData.title,
    seoDescription: {
      seoDescription: `${t('vacancy_for')} ${jobData.title} - Keytrade Bank`,
    },
  };

  const getLanguageNodeForLocale = (data, locale) => {
    const vacancy = data.find((job) => job.languageId === locale);
    const isPageMissing = !vacancy;

    return [
      {
        node_locale: locale,
        slug: isPageMissing ? '404' : `job/${jobData.code}`,
      },
    ];
  };

  data.allLanguages = {};
  data.allLanguages.nodes = [
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'en'),
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'fr'),
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'nl'),
  ];

  const pubDate = new Date(jobData.beginPublication);
  const backLink = `${generateSlug(
    props.pageContext.jobListingPage,
  )}#jobListing`;

  return (
    <Page data={data} {...props}>
      <PageWrapper>
        <Container narrow>
          <InternalLink to={backLink} linkStyle={LinkStyle.BACK}>
            {t('back_to_list')}
          </InternalLink>
          <Title level='h1' size='xxxl' margin='3.2rem 0 3rem' id={id}>
            {jobData.title}
          </Title>
          <MetaList>
            <li>
              <MetaItemTitle>{t('team')}</MetaItemTitle>
              <Paragraph weight='semiBold'>
                {t(`teams.${jobData.type}`)}
              </Paragraph>
            </li>
            <li>
              <MetaItemTitle>{t('contract_type')}</MetaItemTitle>
              <Paragraph weight='semiBold'>
                {t(`contracts.${jobData.contractType}`)}
              </Paragraph>
            </li>
            <li>
              <MetaItemTitle>{t('level')}</MetaItemTitle>
              <Paragraph weight='semiBold'>
                {t(`levels.${jobData.category}`)}
              </Paragraph>
            </li>
          </MetaList>
          <Header>
            <PubDate>
              <Icon icon='icn_calendarLight' color={colors.BlueMoon} />
              {pubDate.toLocaleDateString(
                `${
                  props.pageContext.locale
                }-${props.pageContext.locale.toUpperCase()}`,
                { year: 'numeric', month: 'long', day: 'numeric' },
              )}
            </PubDate>
            <Button href={props.pageContext.jobApplicationURL}>
              {t('apply')}
            </Button>
          </Header>

          <Description>
            {parse(jobData.description, {
              replace: ({ name, children }) => {
                if (name === 'h2') {
                  return (
                    <Title level='h2' size='md' margin='2rem 0 1.2rem'>
                      {domToReact(children)}
                    </Title>
                  );
                }
                if (name === 'p') {
                  return (
                    <Paragraph margin='1.2rem 0'>
                      {domToReact(children)}
                    </Paragraph>
                  );
                }
              },
            })}
          </Description>
          <ApplicationFooter>
            <InternalLink to={backLink} linkStyle={LinkStyle.BACK}>
              {t('back_to_list')}
            </InternalLink>
            <Button size='lg' href={props.pageContext.jobApplicationURL}>
              {t('apply')}
            </Button>
          </ApplicationFooter>
        </Container>
      </PageWrapper>
    </Page>
  );
};

export default JobDetailTemplate;

export const jobDetailPageQuery = graphql`
  query jobDetailPageQuery($locale: String, $job_id: String) {
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allVacanciesJson(filter: { code: { eq: $job_id } }) {
      nodes {
        languageId
        code
        beginPublication
        title
        description
        type
        contractType
        category
      }
    }
    JobsListBlockId: contentfulJobListingBlock {
      id
    }
  }
`;
